import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bandera',
  templateUrl: './bandera.component.html',
  styleUrls: ['./bandera.component.css']
})
export class BanderaComponent implements OnInit {
  @Input() paisId: string;
  constructor() { }

  ngOnInit(): void {
    if (!this.paisId || this.paisId.length < 2 || this.paisId === 'null') {
      this.paisId = '--';
    }
  }

}
