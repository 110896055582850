import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from '../../../shared/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oferta-balance',
  templateUrl: './oferta-balance.component.html',
  styleUrls: ['./oferta-balance.component.css']
})
export class OfertaBalanceComponent implements OnInit {
  @Input() ratioPides: number;
  @Input() ratioOfreces: number;

  constructor(public loginService: LoginService) { }

  ngOnInit(): void {
  }

  onClick(): void {
    Swal.fire('Balanza orientativa', 'AVISO: No tener en cuenta en intercambios de cromos de diferentes colecciones.<br>' +
      'La dificultad de cada cromo se calcula contando las veces que el cromo falta a alguien y las veces que está repetido.<br>' +
      'Cuanto mayor es el valor mayor demanda hay.'
      , 'info');
  }
}
