import { Component, OnInit } from '@angular/core';
import {TestService} from '../../shared/test.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor( public testService: TestService) { }

  ngOnInit(): void {
  }

  increaseCounter(): void {
    this.testService.counter += 1;
  }

}
