import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icono-notifica',
  templateUrl: './icono-notifica.component.html',
  styleUrls: ['./icono-notifica.component.css']
})
export class IconoNotificaComponent implements OnInit {
  @Input() valor = 0;
  constructor() { }

  ngOnInit(): void {
  }

}
