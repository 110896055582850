import { Injectable } from '@angular/core';
import {BaseApiService} from '../../services/baseApiService';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ListaModel} from '../../models/listas/ListaModel';
import {CacheRegistrationService} from '../../services/cacheService/cache-registration.service';
import {LoginService} from '../login.service';

const emptyObservable = of([]);

@Injectable()
export class ListaService extends BaseApiService{
  // private misListas: ListaModel[];
  // private misListasObservados$ = new Subject<ListaModel[]>();

  listasHanCambiado = true;

  constructor(private http: HttpClient,
              private cacheRegistrationService: CacheRegistrationService,
              private loginService: LoginService) {
    super();
    this.setEndpoint('listas');

    // Marcamos como cacheable la consulta de listas del usuario conectado
    if ( loginService.getNick() !== null) {
      this.cacheRegistrationService.addToCache(this.getEndpoint() + '/' + loginService.getNick().toLowerCase());
    }
  }

  public setListasHanCambiado(): void {
    this.listasHanCambiado = true;
  }

  public listarNick( nick: string, refrescar= false ): Observable<ListaModel[]> {

    if ( nick === null ) {
      return emptyObservable;
    }

    const url = this.getEndpoint() + '/' + nick.toLowerCase();
    console.log('listaService.listarNick ' + nick + ' r' + refrescar);
    return this.http.get<ListaModel[]>(url,
      {headers: this.getHttpHeaders(refrescar || this.listasHanCambiado )});
  }

  public guardarNotas( lista: ListaModel): Observable<ListaModel>{
    return this.http.put<ListaModel>(this.getEndpoint() + '/notas/' + lista.id, lista,
      { headers : this.getHttpHeaders()});
  }

  public privada( listaId: number, privado: number): Observable<ListaModel>{
    return this.http.put<ListaModel>(this.getEndpoint() + '/' + listaId + '/' + 'privada/' + privado, privado,
      { headers : this.getHttpHeaders(true)});
  }

  public favorita( listaId: number, favorita: number): Observable<ListaModel>{
    return this.http.put<ListaModel>(this.getEndpoint() + '/' + listaId + '/' + 'favorita/' + favorita, favorita,
      { headers : this.getHttpHeaders(true)});
  }

  public unirse( usuarioId: number, coleccionId: number ): Observable<ListaModel>{
    return this.http.get<ListaModel>(this.getEndpoint() + '/' + usuarioId + '/' + coleccionId,
      { headers : this.getHttpHeaders()});
  }

  public borrar( listaId: number ): Observable<ListaModel>{
    return this.http.delete<ListaModel>(this.getEndpoint() + '/' + listaId,
      { headers : this.getHttpHeaders()});
  }

  // Carga todas mis colecciones
  // como es un servicio solo ocurrirá una vez
  // (TODO Recordar refrescar cuando añada o borre una lista)
/*  public cargaMisListas( nick: string ): Observable<any> {
    if ( this.misListas !== undefined) {
      return of(true);
    }
    this.listarNick( nick ).subscribe( listas => {
        this.misListas = listas;
        this.misListasObservados$.next();
        this.misListasObservados$.complete();
      }
    );
    return this.misListasObservados$.asObservable();
  }*/

  // Buscar por id de colección una de mis listas
/* Esto habría que irlo eliminando ahora tenemos caché en las búsquedas y ya no hay que guardar el resultado de las consultas http
  public buscarMiColeccion( id: number): ListaModel {
    if ( this.misListas === undefined ) {
      console.log ('DEMASIADO PRONTO. NO SE HAN CARGADO MIS LISTAS');
    }
    return this.misListas.find( col => col.coleccionId === id );
  }

  public buscarMiColeccion( id: number): ListaModel {
    if (this.misListas === undefined ) {
      this.listarNick( this.loginService.nick().toLowerCase(), false ).subscribe( listas => {
          this.misListas = listas;
          return this.misListas.find( col => col.coleccionId === id );
        }
      );
    } else {
      return this.misListas.find( col => col.coleccionId === id );
    }
  }
*/

  public buscarColeccion( listas: ListaModel[], id: number): ListaModel {
    const listaModel: ListaModel = listas.find(col => col.coleccionId === id);
    if (!listaModel) {
      console.log('OJO COLECCION NO ENCONTRADA ' + id);
    } else {
      return listaModel;
    }
  }
}
