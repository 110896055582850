import {Component, Input, OnInit} from '@angular/core';
import {PintaNickModel} from '../../../models/usuarios/PintaNickModel';

@Component({
  selector: 'app-nick',
  templateUrl: './nick.component.html',
  styleUrls: ['./nick.component.css']
})

// https://medium.com/@yonem9/angular-c%C3%B3mo-narices-se-anidan-componentes-3f4a7124d751
export class NickComponent implements OnInit {
  // Parámetros externos
  @Input() usuario: PintaNickModel;
  @Input() bloqueo = false;
  loaded = false;

  constructor(
    // public customIconService: CustomIconService
  ) { }

  ngOnInit(): void {
    // this.customIconService.init();
    this.loaded = true;
  }

}
