import {Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {ColeccionResumenModel} from '../../../models/coleccion/ColeccionResumenModel';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {EditorialModel} from '../../../models/coleccion/EditorialModel';
import {ColeccionesService} from '../../../shared/coleccion/colecciones.service';

@Component({
  selector: 'app-lista-colecciones',
  templateUrl: './lista-colecciones.component.html',
  styleUrls: ['./lista-colecciones.component.css']
})
export class ListaColeccionesComponent implements OnInit, OnChanges {
  loaded = false;
  @Input() listaColecciones: ColeccionResumenModel[];
  @Input() listaEditoriales: EditorialModel[];

  columnasActivas: string[] = ['nombre', 'editorialNombre', 'anoPublica', 'coleccionistas'];

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChildren(MatTable) todasLasMatTable !: QueryList<MatTable<ColeccionResumenModel>>;

  constructor(private coleccionesService: ColeccionesService) { }

  ngOnInit(): void {
    this.loaded = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rellenarEditorialNombre();
    this.coleccionesService.rellenarPopular(this.listaColecciones);
  }

  // Rellena el nombre de la editorial
  rellenarEditorialNombre( ): void {
    this.listaColecciones.map( obj => {
      obj.editorialNombre = this.listaEditoriales.find( e => e.id === obj.editorialId).nombre; return obj; } );
  }

  sortLista(sort: Sort): number {
    const data = this.listaColecciones.slice();
    if (!sort.active || sort.direction === '') {
      this.listaColecciones = data;
      return 0;
    }
    this.listaColecciones = this.mySort(sort, data);
  }

  mySort(sort: Sort, data: ColeccionResumenModel[]): ColeccionResumenModel[] {
    return data.sort((a, b) => {

      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'nombre': return compare(a.nombre, b.nombre, isAsc);
        case 'editorialNombre': return compare(a.editorialNombre, b.editorialNombre, isAsc);
        case 'anoPublica': return compare(a.anoPublica, b.anoPublica, isAsc);
        case 'coleccionistas': return compare(a.coleccionistas, b.coleccionistas, isAsc);

        default: return 0;
      }
    });

    function compare(a: number | string, b: number | string, isAsc: boolean): number {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }
}

