import {environment} from '../../environments/environment';
import {HttpHeaders} from '@angular/common/http';

/**
 * Clase Base para todos los servicios que hacen llamadas al api
 */
export class BaseApiService {
  private baseEndpoint = environment.apiUrl;
  private cabeceras: HttpHeaders = new HttpHeaders({'Content-Type' : 'application/json' });
  private cabecerasRefrescar: HttpHeaders = new HttpHeaders({'Content-Type' : 'application/json', 'reset-cache': 'true'});

  protected setEndpoint( endpoint ): void {
    this.baseEndpoint = this.baseEndpoint.concat(endpoint);
  }
  protected getEndpoint(): string {
    return this.baseEndpoint;
  }

  protected getHttpHeaders(refrescar = false ): HttpHeaders {

    if (refrescar) {
      // poner cabecera que invalida la caché para la petición
      return this.cabecerasRefrescar;
    }
    else {
      return this.cabeceras;
    }
  }
}
