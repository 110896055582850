import {Component, Input} from '@angular/core';
import {UtilService} from '../../../services/util.service';

@Component({
  selector: 'app-go-top',
  templateUrl: './go-top.component.html',
  styleUrls: ['./go-top.component.css']
})
export class GoTopComponent {
  @Input() anchor: string;
  constructor( public utilService: UtilService ) { }
}
