<h1 mat-dialog-title><mat-icon class="material-icons-outlined">password</mat-icon> Enviar nueva contraseña
  <app-btn-close-dialog (click)='onCancel()'></app-btn-close-dialog>
</h1>

<div mat-dialog-content>
  <p>Para enviarte una nueva contraseña o un mensaje de activación, indica la dirección de correo electrónico de tu registro.</p>
  <form [formGroup]="formGroup">
    <!-- email -->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Dirección de correo electrónico:</mat-label>
      <input type="email" matInput maxlength="100" required
             pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
             placeholder="Escribe tu dirección de correo electrónico"
             formControlName="email">
    </mat-form-field>
    <div *ngIf="email.invalid && email.touched">
      <small *ngIf="email.errors?.required" class="mat-error">
        Email obligatorio
      </small>
      <small *ngIf="email.errors?.pattern" class="mat-error">
        Email incorrecto
      </small>
    </div>

    <!-- challenge -->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>¿Que carácteres aparecen en la imagen?</mat-label>
      <input type="text" matInput maxlength="100" minlength="3" required
             placeholder="Indica que letras o números aparecen en la imagen" formControlName="challenge">
      <div *ngIf="challenge.invalid && challenge.touched">
        <small *ngIf="challenge.errors?.required" class="mat-error">
          Campo obligatorio
        </small>
      </div>

    </mat-form-field>

    <!-- captcha -->
    <img *ngIf="loaded" [src]="captchaImage" alt="Captcha imagen">

    <div class="botonera-flex-container">
      <button mat-raised-button mat-dialog-close class="botonera-flex-item" [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>chevron_left</mat-icon> Volver</button>
      <button mat-raised-button class="botonera-flex-item" [disabled]="!formGroup.valid" (click)="onSave()"><mat-icon>save</mat-icon> Guardar</button>
    </div>
  </form>
</div>
