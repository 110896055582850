<!-- Línea principal de título -->
<a id="top" tabindex="-1"></a>
<mat-toolbar class="toolbar1">
  <button *ngIf="loginService.isLogged()" mat-icon-button aria-label="Icono de opciones de menu"
          title="Noticias, ayuda, rankings, ..." [matMenuTriggerFor]="menu">
    <mat-icon>{{iconosMenu.opciones}}</mat-icon>
  </button>

<!--  <button mat-icon-button aria-label="Example icon-button with heart icon">-->
<!--    <mat-icon>favorite</mat-icon>-->
<!--  </button>-->
  <a aria-label="Página de inicio" href="{{staticWebUrl}}">
<!--        [routerLink]="'/home'">-->
    <img ngSrc="../../../assets/icons/apple-touch-icon-50x50.webp" height="50" width="50"
         alt="Logo CromosRepes.com" aria-label="Logo CromosRepes.com">
  </a>
  <a aria-label="Título de la página"  class="hide-xs" style="vertical-align: center;" href="{{staticWebUrl}}">
<!--        [routerLink]="'/home'"-->
  CromosRepes</a>
  <span class="example-spacer"></span>

  <a *ngIf="!loginService.isLogged()" mat-button aria-label="Icono de registro"
          href="{{staticWebUrl}}/usuario/registro">
    <mat-icon class="material-icons-outlined">{{iconosMenu.registro}}</mat-icon>Registrarme
  </a>
  <button *ngIf="!loginService.isLogged()" mat-button aria-label="Icono de login"
          [routerLink]="'/login'">
    <mat-icon>{{iconosMenu.login}}</mat-icon>Entrar
  </button>
  <span *ngIf="loginService.isLogged()" aria-label="Icono de menú de usuario" [matMenuTriggerFor]="menuUsuario"
    style="cursor: pointer">
    <app-icono-socio [socio]="loginService.getSocio()"></app-icono-socio>
    <span class="hide-gt-xs">{{loginService.getNick().substr(0,3).toUpperCase()}}</span>
    <span class="hide-xs">{{loginService.getNick()}}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </span>
</mat-toolbar>

<!-- segunda línea de título -->
<mat-toolbar-row *ngIf="loginService.isLogged()" class="toolbar2">
    <span (click)="loginService.backNavigation()" matRipple style="cursor: pointer" aria-label="Volver atrás" title="Volver atrás">
    <mat-icon aria-label="Volver atrás">{{iconosMenu.volver}}</mat-icon><span class="hide-lt-md">Volver</span>
  </span>
  <span class="example-spacer"></span>
  <a routerLink="/colecciones" matRipple aria-label="Colecciones" title="Colecciones">
    <mat-icon aria-label="Icono de Todas las colecciones">{{iconosMenu.colecciones}}</mat-icon><span class="hide-xs">Colecciones</span>
  </a>
  <span class="example-spacer"></span>
  <app-icono-mensajes [numMsgPdte]="numMsgPdte"></app-icono-mensajes>

  <span class="example-spacer"></span>
  <app-icono-ofertas [numOfertaPdte]="numOfertaPdte"></app-icono-ofertas>

  <span class="example-spacer"></span>
  <a routerLink="/listas/{{loginService.getNick()}}" matRipple aria-label="Mis listas" title="Mis listas">
    <mat-icon  aria-label="Icono de Mis listas">{{iconosMenu.listas}}</mat-icon><span class="hide-xs">Listas</span>
  </a>
  <span class="example-spacer"></span>
  <a routerLink="/usuarios/buscar" matRipple aria-label="Buscar usuarios, favoritos e ignorados" title="Buscar usuarios, favoritos e ignorados">
    <mat-icon aria-label="Icono de búsqueda">{{iconosMenu.buscar}}</mat-icon><span class="hide-lt-md">Buscar</span>
  </a>
</mat-toolbar-row>

<mat-menu #menu="matMenu">
  <a mat-menu-item [routerLink]="'/colecciones'">
    <mat-icon>{{iconosMenu.colecciones}}</mat-icon>
    <span>Colecciones</span>
  </a>
  <a mat-menu-item href="{{staticWebUrl}}noticias">
    <mat-icon class="material-icons-outlined">{{iconosMenu.noticias}}</mat-icon>
    <span>Noticias</span>
  </a>
  <a mat-menu-item [routerLink]="'/ayuda'">
    <mat-icon>{{iconosMenu.ayuda}}</mat-icon>
    <span>Ayuda</span>
  </a>
  <a mat-menu-item [routerLink]="'/ranking'">
    <mat-icon class="material-icons-outlined">{{iconosMenu.ranking}}</mat-icon>
    <span>Ranking</span>
  </a>
  <a mat-menu-item href="{{staticWebUrl}}enlaces">
    <mat-icon class="material-icons-outlined">{{iconosMenu.recomendamos}}</mat-icon>
    <span>Recomendamos</span>
  </a>
  <span mat-menu-item (click)="cambiarTema()" [ngSwitch]="theme">
      <span *ngSwitchCase="'light-theme'"><mat-icon class="material-icons-outlined">dark_mode</mat-icon> Modo oscuro</span>
      <span *ngSwitchCase="'dark-theme'"><mat-icon class="material-icons-outlined">light_mode</mat-icon> Modo claro</span>
  </span>
</mat-menu>

<mat-menu #menuUsuario="matMenu" xPosition="before">
  <a mat-menu-item [routerLink]="'/listas/'+loginService.getNick()">
    <mat-icon>{{iconosMenu.listas}}</mat-icon>
    <span>Mis Listas</span>
  </a>
  <a mat-menu-item [routerLink]="'/cambios/ofertas'">
    <mat-icon class="material-icons-outlined">{{iconosMenu.ofertas}}</mat-icon>
    <span>Cambios</span>
  </a>
  <a mat-menu-item [routerLink]="'/mensajes'">
    <mat-icon>{{iconosMenu.mensajes}}</mat-icon>
    <span>Mensajes</span>
  </a>
  <a mat-menu-item>
    <mat-icon>{{iconosMenu.suscripcion}}</mat-icon>
    <span [routerLink]="'/suscripcion'">Suscripción</span>
  </a>
  <a mat-menu-item [routerLink]="'/evalua/recibidas/'+loginService.getNick()">
    <mat-icon class="material-icons-outlined">{{iconosMenu.evaluaciones}}</mat-icon>
    <span>Evaluaciones</span>
  </a>
  <a mat-menu-item [routerLink]="'/preferencias'">
    <mat-icon class="material-icons-outlined">{{iconosMenu.preferencias}}</mat-icon>
    <span>Preferencias</span>
  </a>
  <button mat-menu-item (click) ="onLogout()">
    <mat-icon class="material-icons-outlined">{{iconosMenu.logout}}</mat-icon>
    <span>Desconectar</span>
  </button>
</mat-menu>
