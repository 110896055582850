import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rgpd-resumen',
  templateUrl: './rgpd-resumen.component.html',
  styleUrls: ['./rgpd-resumen.component.css']
})
export class RgpdResumenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
