import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icono-nick',
  templateUrl: './icono-nick.component.html',
  styleUrls: ['./icono-nick.component.css']
})
export class IconoNickComponent implements OnInit {

  @Input() socio: number;
  @Input() nick: string;
  nickAbreviado: any;

  constructor() { }

  ngOnInit(): void {
    this.nickAbreviado = this.nick.substr(0, 3).toUpperCase();
  }

}
