<form [formGroup]="passwordForm" autocomplete="off">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Nueva contraseña:</mat-label>
    <input type="password" matInput maxlength="100" minlength="8" required
           placeholder="Escribe tu nueva contraseña" formControlName="password">
    <div *ngIf="password.touched && password.errors?.required" class="mat-error">Campo obligatorio</div>
    <div *ngIf="passwordErrorsMinlength" class="mat-error">
      La nueva contraseña debe tener 8 mínimo de longitud
    </div>
  </mat-form-field>

  <!-- Repite nueva contraseña -->
  <mat-form-field appearance="outline" class="full-width" *ngIf="passwordForm.controls['password'].value.length>0">
    <mat-label>Confirma nueva contraseña:</mat-label>
    <input type="password" matInput maxlength="100" minlength="8" required
           placeholder="Repite tu nueva contraseña" formControlName="passwordRepeat">
    <div *ngIf="passwordForm.errors?.noMatch && !passwordErrorsMinlength" class="mat-error">
      La contraseña repetida no coincide.
    </div>
  </mat-form-field>

</form>
