import { Injectable } from '@angular/core';
import {BaseApiService} from '../services/baseApiService';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UriCachingInterceptor} from '../services/cacheService/uri-caching-interceptor.service';
import {Location} from '@angular/common';
import {OfertaNotaModel} from '../models/oferta/OfertaNotaModel';

@Injectable()
export class LoginService extends BaseApiService {

  LOCAL_STORAGE_USER_ID   = 'jti';
  LOCAL_STORAGE_USER_NICK = 'sub';
  LOCAL_STORAGE_SOCIO     = 'socio';
  LOCAL_STORAGE_TOKEN     = 'token';
  LOCAL_STORAGE_ROLES     = 'CLAIM_TOKEN';

  private logged = false;
  private tokenProps = new Map<string, string>();

  constructor(private http: HttpClient,
              private uriCachingInterceptor: UriCachingInterceptor,
              private location: Location) {
    super();
    // this.setEndpoint('login');
    if (this.getLoggedUserId() > 0 ) {
      this.logged = true;
    }
    console.log('LOGGED = ' + this.logged);
  }

  public backNavigation(): void {
    this.location.back();
  }

  public isLogged(): boolean {
    return this.logged;
  }

  public getLoggedUserId(): number {
    const loggedUserId = +localStorage.getItem(this.LOCAL_STORAGE_USER_ID);
    if ( loggedUserId === null || loggedUserId === undefined) {
      console.log('ERROR: REDIRIGIR A LOGIN');
    }
    return loggedUserId;
  }

  public getNick(): string {
    return localStorage.getItem(this.LOCAL_STORAGE_USER_NICK);
  }

  public getSocio(): number {
    return +localStorage.getItem(this.LOCAL_STORAGE_SOCIO);
  }

  public getAdmin(): boolean {
    const roles = localStorage.getItem(this.LOCAL_STORAGE_ROLES);
    return (!(roles === null || !roles.includes('ROLE_ADMIN'))) ;
  }

  public login(nombre: string, password: string, challenge: string, acepto: boolean): Observable<HttpResponse<any>> {
    const payload = { user: nombre, password, challenge, acepto};
    return this.http.post(this.getEndpoint() + 'login', payload, { headers: this.getHttpHeaders(), observe: 'response'});

    // TODO INVESTIGAR SHAREREPLAY PARA NO REPETIR PETICIONES
    // .pipe(shareReplay(1));
  }

  public logout(): void {
    localStorage.removeItem( this.LOCAL_STORAGE_USER_ID );
    localStorage.removeItem( this.LOCAL_STORAGE_USER_NICK );
    localStorage.removeItem( this.LOCAL_STORAGE_SOCIO );
    localStorage.removeItem( this.LOCAL_STORAGE_TOKEN );
    this.logged = false;
  }

  saveToken( token: string ): void {

    localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);

    this.logged = true;
    console.log('LOGGED = TRUE');
    // Limpiamos el servicio de caché para que no viajen peticiones con tokens caducados.
    this.uriCachingInterceptor.clearCache();

    const parsedToken = this.decodeToken(token);
    for (const [key, value] of Object.entries(parsedToken)) {
      if (typeof value === 'string') {
        localStorage.setItem(key, value); // TODO QUITAR CONSULTAS A LOCALSTORAGE
        this.tokenProps.set(key, value);
      } else if (typeof value === 'number') {
        localStorage.setItem(key, value.toFixed()); // TODO QUITAR CONSULTAS A LOCALSTORAGE
        this.tokenProps.set(key, value.toFixed());
      }
    }
  }

  private decodeToken(token: string = ''): any {
    if (token === null || token === '') { return { upn: '' }; }
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((window as any).escape(window.atob(output)));
  }

  // ---------------------
  // Funciones de refresco
  // ---------------------
  public refrescaOfertas(): Observable<number[]> {
    const url = this.getEndpoint() + 'ofertas/refresco';
    return this.http.get< number[] >(url,  {headers: this.getHttpHeaders( true)});
  }

  public refrescaMensajes(): Observable<number> {
    const url = this.getEndpoint() + 'mensajes/pendientes';
    return this.http.get< number >(url, {headers: this.getHttpHeaders(true)});
  }

}
