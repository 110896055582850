import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-dificultad',
  templateUrl: './dificultad.component.html',
  styleUrls: ['./dificultad.component.css']
})
export class DificultadComponent implements OnInit {
  @Input() ratio: number;
  constructor() { }

  ngOnInit(): void {
  }

}
