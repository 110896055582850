export const Constantes = {
  estadoOfertaPendiente:   0,

  estadoOfertaRechazada:           10,
  estadoOfertaCaducada:            11,
  estadoOfertaRevocada:            12,
  estadoOfertaRevocadaSancionada:  13,

  estadoOfertaAceptada:   1,
  estadoOfertaFinalizada: 5,
} as const;

export const NivelSocio = {
  colaborador: 1,
  vip: 2,
  oro: 4,
  diamante: 5
} as const;

export const IconosMenu = {
  opciones: 'menu',
  registro: 'edit',
  login: 'person_outlined',

  // 2º línea
  volver: 'chevron_left',
  colecciones: 'apps',
  listas: 'rule',
  buscar: 'search',

  // menú izquierda
  noticias: 'campaign',
  ayuda: 'help_outlined',
  ranking: 'leaderboard',
  recomendamos: 'loyalty',

  // menú derecha
  ofertas: 'question_answer',
  mensajes: 'mail_outlined',
  suscripcion: 'star',
  evaluaciones: 'thumb_up_alt',
  preferencias: 'settings',
  logout: 'logout'
} as const;

export const IconosFichaColeccion = {
  unirse: 'add',
  enlaces: 'link',
  buscados: 'zoom_in'
} as const;

export const IconosListaDetalle = {
  buscarFaltas: 'highlight_outlined',
} as const;

export const IconosBuscarFaltas = {
  intercambiar: 'shuffle',
  ocultar: 'clear',
  masResultados: 'cloud_download',
  filtro: 'filter_alt',
  marcado: 'new_releases',
  descartado: 'not_interested'
} as const;

export const IconosMarcar = {
  guardar: 'save',
  sumar: 'add',
  restar: 'remove',
} as const;

export const IconosTipoUsuario = {
  ignorado: 'pan_tool',
  favorito: 'favorite_border',
} as const;


export const IconosCambios = {
  enviar: 'upload',
  recibir: 'download',
  confirmar: 'send',
  cambiosAceptados: 'handshake',
  finalizados: 'sports_score',
  rechazados: 'delete',
  verDetalle: '',
  revocarOferta: 'highlight_off',
  rechazarOferta: 'highlight_off',
  aceptarOferta: 'forward_to_inbox',
  mensajes: 'chat_bubble_outline',
  marcarEnviado: 'forward_to_inbox',
  marcarRecibido: 'markunread_mailbox',
  rectificarEvaluacion: 'sentiment_satisfied',

} as const;

export const TRACKING_AUTO = {
  clave: 1,
  valor: 'Automático',
  icon: 'sync'
} as const;

export const TRACKING_MANUAL = {
  clave: 2,
  valor: 'Manual',
  icon: 'sync_disabled'
} as const;


