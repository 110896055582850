&nbsp;
<mat-divider></mat-divider>
<div class="footer">
  <small>
    <a routerLink="ayuda">Ayuda</a> -
    <a href="{{staticWebUrl}}ayuda/legal">Aviso Legal</a> -
    <a href="{{staticWebUrl}}ayuda/privacidad">Privacidad y condiciones de uso</a> -
<!--    <a href="{{staticWebUrl}}ayuda/cookies">Cookies</a> </small>-->
    <a (click)="showCookies()">Cookies</a> </small>
    <span class='spacer'></span>
</div>

