import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icono-mensajes',
  templateUrl: './icono-mensajes.component.html',
  styleUrls: ['./icono-mensajes.component.css']
})
export class IconoMensajesComponent implements OnInit {
  @Input() numMsgPdte: number;

  constructor() { }

  ngOnInit(): void {
  }

}
