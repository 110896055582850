import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { AppRoutingModule } from './app-routing.module';


import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptorService} from './services/token.interceptor.service';
import {CommonModule, registerLocaleData} from '@angular/common';
import {CacheRegistrationService} from './services/cacheService/cache-registration.service';
import {UriCachingInterceptor} from './services/cacheService/uri-caching-interceptor.service';
import {InitialModule} from './initial/initial.module';
import {MatNativeDateModule} from '@angular/material/core';
import {CompartidoModule} from './components/compartido/compartido.module';



registerLocaleData(localeEs);
registerLocaleData(localeEn);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        HttpClientModule,
        InitialModule,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        AppRoutingModule,
        SharedModule.forRoot(),
        MatNativeDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        CompartidoModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'es-ES'},
        // {provide: LOCALE_ID, useValue: 'en'},
        CacheRegistrationService,
        {provide: HTTP_INTERCEPTORS, useClass: UriCachingInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    ],
    exports: [
        CommonModule,

    ],

    bootstrap: [AppComponent]
})
export class AppModule { }
