import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

declare var Cookiebot: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  staticWebUrl = environment.staticWebUrl;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * El consentimiento de las cookies de <app.cromosrepes.com> funcionan de forma independiente
   * y aislada de las cookies del dominio raiz <cromosrepes.com>
   * Por tanto se puede elegir tener activas las cookies de uno u otro dominio de forma independiente.
   * Hay una cookie de la web estática (CookieConsent=xxx;domain=cromosrepes.com;path=/)
   * y otra diferente de la SPA de angular con el mismo nombre
   * (CookieConsent=xxx;domain=app.cromosrepes.com;path=/)
   * Por eso ponemos un botón en el footer, para cambiar el consentimiento en la app que no afecta al
   * consentimiento en la parte estática de la web.
   */
  showCookies(): void  {
    (window as any).Cookiebot.show();
  }
}
