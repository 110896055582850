import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-btn-close-dialog',
  templateUrl: './btn-close-dialog.component.html',
  styleUrls: ['./btn-close-dialog.component.css']
})
export class BtnCloseDialogComponent {

  @Input() onCancel: () => void;

}
