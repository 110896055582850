import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-mini-nick',
  templateUrl: './mini-nick.component.html',
  styleUrls: ['./mini-nick.component.css']
})
export class MiniNickComponent implements OnInit {
  loaded = false;
  @Input() nick = '';
  @Input() soc: number;
  @Input() pos: number;
  @Input() neg: number;
  @Input() ser: number;
  @Input() doblelinea: boolean;
  @Input() bloqueo = false;

  cssClassNick = '';
  cssClassEvalua = '';

  constructor() { }

  ngOnInit(): void {
    if (this.doblelinea === null) {
      this.doblelinea = false;
    }

    if ( this.doblelinea && this.nick && this.nick.length > 15 ) {
      this.nick = this.nick.substr(0, 15) + '..';
    }

    if ( this.soc > 0 ) {
      this.cssClassNick = 'negrita';
    }

    if ( this.neg < -2 || this.ser < 4) {
      this.cssClassEvalua = 'danger';
    } else if (this.neg === -2 || (this.ser > 3 && this.ser < 6)) {
      this.cssClassEvalua = 'warning';
    } else if (this.neg === -1 || (this.ser > 5 && this.ser < 9)) {
      this.cssClassEvalua = 'normal';
    } else {
      this.cssClassEvalua = 'perfect';
    }

    this.loaded = true;
  }

}
