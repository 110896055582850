import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApiService} from '../../services/baseApiService';
import {Observable} from 'rxjs';
import {ColeccionResumenModel} from '../../models/coleccion/ColeccionResumenModel';
import {CacheRegistrationService} from '../../services/cacheService/cache-registration.service';
import {ColeccionDetalleModel} from '../../models/coleccion/ColeccionDetalleModel';

@Injectable({
  providedIn: 'root'
})
export class ColeccionesService extends BaseApiService{

  constructor(private http: HttpClient,
              private cacheRegistrationService: CacheRegistrationService) {
    super();
    this.setEndpoint('public/colecciones');

    // Marcamos como cacheable la consulta de todas las colecciones
    this.cacheRegistrationService.addToCacheTime(this.getEndpoint() + '/todas/0/0', 1);
    this.cacheRegistrationService.addToCacheTime(this.getEndpoint() + '/nuevas', 5);
  }

  /* Lista de resumen de colecciones (CACHE) */
  public lista( cadenaBuscar: string, editorial: number, tipo: number, refrescar= false ): Observable<ColeccionResumenModel[]> {
    const url = this.getEndpoint() + '/' + cadenaBuscar + '/' + editorial + '/' + tipo;
    return this.http.get<ColeccionResumenModel[]>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  // Colecciones por estado
  public getColeccionesByEstado( refrescar= true ): Observable<ColeccionDetalleModel[]> {
    const url = this.getEndpoint() + '/estado/0';
    return this.http.get<ColeccionDetalleModel[]>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  // Colecciones por estado
  public getColeccionesAutorizadas( refrescar= true ): Observable<ColeccionDetalleModel[]> {
    const url = this.getEndpoint() + '/autorizadas';
    return this.http.get<ColeccionDetalleModel[]>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  // Colecciones nuevas
  public getColeccionesNuevas( refrescar= false ): Observable<ColeccionResumenModel[]> {
    const url = this.getEndpoint() + '/nuevas';
    return this.http.get<ColeccionResumenModel[]>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  // Calcula si la colección es popular
  // Si su fecha de creación fue durante los últimos 9 meses y tiene al menos 9 coleccionistas.
  rellenarPopular( listaColecciones: ColeccionResumenModel[]): void {
    const d = new Date();
    d.setMonth(d.getMonth() - 9);

    listaColecciones.map( obj => {
      if (obj.fecha.toString() > d.toISOString() && obj.coleccionistas > 9 ) {
        obj.popular = true;
      } else {
        obj.popular = false;
      }
      return obj;
    });
  }

}
