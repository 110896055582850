<div class="div-center">
 <mat-card appearance="outlined" id="login">
   <form [formGroup]="formGroup">
    <img src="../../../assets/img/logo100alto.png" height="100" width="346" alt="Logotipo CromosRepes.com"/>
    <mat-card-title>Inicia sesión</mat-card-title>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Nombre de usuario o email </mat-label>
        <input matInput formControlName="nick" placeholder="Nombre de usuario o correo electrónico">
        <mat-icon matSuffix>portrait</mat-icon>
      </mat-form-field>
      <br />
      <mat-form-field>
        <mat-label>Contraseña</mat-label>
        <input type="password" matInput formControlName="password" placeholder="password">
        <mat-icon matSuffix>vpn_key</mat-icon>
      </mat-form-field>

      <app-check-condiciones *ngIf="hayNuevoCondicionado" [condicionesForm]="condicionesFormGroup"></app-check-condiciones>

      <mat-form-field *ngIf="muchosIntentos">
        <mat-label>Código de verificación</mat-label>
        <input matInput required formControlName="challenge" placeholder="¿Que carácteres aparecen en la imagen?">
        <mat-icon matSuffix>image</mat-icon>
      </mat-form-field>
      <!-- captcha -->
      <img *ngIf="muchosIntentos && captchaLoaded" [src]="captchaImage" alt="Captcha imagen">

      <br>
      <button mat-raised-button [disabled]="!formGroup.valid" (click)="login()" color="primary">Entrar</button>
    </mat-card-content>
    <mat-card-actions align="center">
      <button mat-raised-button color="secondary" [routerLink]="'/signup'">No tengo usuario</button>
      <p></p>
      <button mat-raised-button color="secondary" (click)="onPedirPassword()">No recuerdo mi contraseña</button>
    </mat-card-actions>
    </form>
</mat-card>

</div>
