import { Component } from '@angular/core';
import {UtilService} from './services/util.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'cr-angular2';

  constructor(public utilService: UtilService) {
  }
}
