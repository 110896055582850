import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'app-password-repeat',
  templateUrl: './password-repeat.component.html',
  styleUrls: ['./password-repeat.component.css']
})
export class PasswordRepeatComponent implements OnInit {
  @Input() passwordForm: UntypedFormGroup;
  constructor() { }

  ngOnInit(): void {
    this.passwordForm.setValidators(this.myPasswordMatchValidator);
    this.passwordForm.get('password').setValue('');
    this.passwordForm.get('passwordRepeat').setValue('');
  }

  myPasswordMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const password = control.get('password').value;
    const passwordRepeat = control.get('passwordRepeat').value;

    if ( password !== passwordRepeat) {
      return { noMatch: true };
    } else {
      return null;
    }
  }

  get password(): any {
    return this.passwordForm.get('password');
  }

  get passwordErrorsMinlength(): any {
    if (!this.passwordForm.get('password') || !this.passwordForm.get('password').errors ) {
      return null;
    }
    return this.passwordForm.get('password').errors?.minlength;
  }
}
