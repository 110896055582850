import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'app-email-repeat',
  templateUrl: './email-repeat.component.html',
  styleUrls: ['./email-repeat.component.css']
})
export class EmailRepeatComponent implements OnInit {
  @Input() emailForm: UntypedFormGroup;
  emailOriginal: string;

  constructor() {  }

  ngOnInit(): void {
    this.emailForm.setValidators(this.myEmailMatchValidator);
    this.emailOriginal = this.emailForm.get('email').value;
  }

  myEmailMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const email = control.get('email').value;
    const emailRepeat = control.get('emailRepeat').value;

    if ( email !== this.emailOriginal && email !== emailRepeat) {
      return { noMatch: true };
    } else {
      return null;
    }
  }

  get email(): any {
    return this.emailForm.get('email');
  }
}
