<ng-container>
  <div class="wrapper-columns">
    <div>
      <h1>Cambia tus cromos con miles de amigos.</h1>
      <h2>Bienvenido a la mayor comunidad de intercambio de cromos, estampas, tarjetas, figuras y todo tipo de coleccionables.</h2>
      <p>Completa tus colecciones de la forma más sencilla.</p>
      <ul>
        <li>1º Elige una colección de las más de <b routerLink="/colecciones">8000 colecciones</b>.</li>
        <li>2º Rellena tus faltas y repes cómodamente.</li>
        <li>3º Usa el buscador automático, para saber cual de los compañeros tienen tus faltas y buscan tus repetidos.</li>
      </ul>
      <p>Cambiar cromos es así de fácil, disfruta acabando tus colecciones y ayudando también a tus nuevos amigos a terminarlas.</p>
      <p>Y lleva siempre contigo en tu móvil tus listas actualizadas.</p>
      <div class="div-center">
        <button mat-raised-button [routerLink]="'/signup'" color="primary">Apúntate, es gratis!!!</button>
      </div>
    </div>
    &nbsp;
    <div class="hide-xs">
      <div class="full-width div-center" >
        <img ngSrc="../../../assets/img/parejita.webp" height="336" width="336" alt="Niños cambiando cromos"/>
      </div>
    </div>
    &nbsp;
    <div class="full-width">
      <button mat-raised-button [routerLink]="'/colecciones'" >Ver las colecciones más populares</button>
    </div>

  </div>

</ng-container>


