import { Pipe, PipeTransform } from '@angular/core';
/** https://careydevelopment.us/blog/angular-how-to-truncate-text-with-a-custom-pipe */
@Pipe({
  name: 'leftTrim', pure: true
})
export class LeftTrimPipe implements PipeTransform {
  // Elimina ceros iniciales
  transform(text: string): string {
    return text.replace(/^[0]+/g, '');
  }

}
