import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {TitleComponent} from './title/title.component';
import {CompartidoModule} from '../components/compartido/compartido.module';
import {MatMenuModule} from '@angular/material/menu';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FormulariosModule} from '../components/formularios/formularios.module';
import {MatRippleModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDividerModule} from '@angular/material/divider';
import { IconoMensajesComponent } from './title/icono-mensajes/icono-mensajes.component';
import { IconoOfertasComponent } from './title/icono-ofertas/icono-ofertas.component';
import { IconoNickComponent } from './title/icono-nick/icono-nick.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [TitleComponent, FooterComponent, LoginComponent, HomeComponent,
    IconoMensajesComponent, IconoOfertasComponent, IconoNickComponent],
    imports: [
        CommonModule,
        CompartidoModule,
        MatMenuModule,
        MatToolbarModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        FormulariosModule,
        MatRippleModule,
        MatSnackBarModule,
        MatDividerModule,
        MatTooltipModule,
        NgOptimizedImage
    ],
  exports: [TitleComponent, FooterComponent, LoginComponent, HomeComponent,
    IconoMensajesComponent, IconoOfertasComponent, IconoNickComponent]
})
export class InitialModule { }
