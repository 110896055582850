import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {PublicService} from '../../../shared/public.service';
import {ResetPasswordModel} from '../../../models/preferencias/ResetPasswordModel';
import Swal from 'sweetalert2';
import {UtilService} from '../../../services/util.service';

@Component({
  selector: 'app-pref-pedir-password',
  templateUrl: './pref-reset-password.component.html',
  styleUrls: ['./pref-reset-password.component.css']
})
export class PrefResetPasswordComponent implements OnInit {
  loaded = false;

  formGroup: UntypedFormGroup;

  public captchaImage: any;

  constructor(public dialogRef: MatDialogRef<PrefResetPasswordComponent>,
              public publicService: PublicService,
              public utilService: UtilService) {
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(''),
      challenge: new UntypedFormControl('')
    });

    this.getNewCaptcha();
  }

  getNewCaptcha(): void {
    this.publicService.getCaptcha().subscribe( imagen => {
      this.createImageFromBlob(imagen);
      this.loaded = true;
    });
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.captchaImage = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  onCancel(): void {
    this.dialogRef.close(undefined);
  }

  ngOnInit(): void {
  }

  onSave( ): void {
    const resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
    resetPasswordModel.email = this.formGroup.get('email').value;
    resetPasswordModel.challenge = this.formGroup.get('challenge').value;

    Swal.fire({title: 'Enviando nueva contraseña', icon: 'info',
      allowEscapeKey: false, showConfirmButton: false, allowOutsideClick: false});
    Swal.showLoading();
    this.publicService.resetPassword(resetPasswordModel).subscribe(lista => {

      Swal.fire({title: 'Contraseña enviada. Revisa tu buzón en unos minutos y la carpeta de correo no deseado', icon: 'info'});

      this.dialogRef.close(true);
    }, err => {
      this.getNewCaptcha();

      console.log('Error Enviando nueva contraseña' + err.value);
      this.utilService.mostrarMsgError(err, 'Error enviando nueva contraseña');
    });
  }

  get email(): any {
    return this.formGroup.get('email');
  }
  get challenge(): any {
    return this.formGroup.get('challenge');
  }


}
