import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-check-condiciones',
  templateUrl: './check-condiciones.component.html',
  styleUrls: ['./check-condiciones.component.css']
})
export class CheckCondicionesComponent implements OnInit {
  staticWebUrl = environment.staticWebUrl;

  @Input() condicionesForm: UntypedFormGroup;
  @Input() dialogRef: MatDialogRef<any>;
  constructor() { }

  ngOnInit(): void {
  }

  get acepto(): any {
    return this.condicionesForm.get('acepto');
  }

  onVerPolitica(): void {
    // Redirigimos a la web antigua.
    window.location.href = this.staticWebUrl + 'ayuda/privacidad';

    if ( this.dialogRef !== undefined && this.dialogRef !== null) {
      this.dialogRef.close(undefined);
    }
  }
}
