import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icono-vacaciones',
  templateUrl: './icono-vacaciones.component.html',
  styleUrls: ['./icono-vacaciones.component.css']
})
export class IconoVacacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
