import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {LoginService} from '../shared/login.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor( private router: Router, private loginService: LoginService ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('TokenInterceptor');
    let newHeaders = req.headers;
    const token = localStorage.getItem('token');
    if ( token ) {
      // If we have a token, we append it to our new headers
      newHeaders = newHeaders.append('Authorization', token);
    }
    // Finally we have to clone our request with our new headers
    // This is required because HttpRequests are immutable
    const authReq = req.clone({headers: newHeaders});

    // Then we return an Observable that will run the request
    // or pass it to the next interceptor if any
    // return next.handle(authReq);

    return next.handle(authReq).pipe( tap(() => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401 && err.status !== 403) {
            return;
          }
          this.loginService.logout();
          localStorage.removeItem('token');
          if ( err.status === 403 ) {
            localStorage.removeItem('ultimaRuta');
          } else {
            localStorage.setItem('ultimaRuta', this.router.routerState.snapshot.url);
          }
          this.router.navigate(['login']);
          if (Swal.isVisible()) {
            Swal.close();
          }
        }
      }));

  }


}
