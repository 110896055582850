import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {HttpStatusCode} from '@angular/common/http';
import {timeout} from 'rxjs/operators';

/**
 * Funciones comunes de utilidad
 */
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  esperandoCarga: boolean;
  timeout: number;

  /** Scroll hasta el elemento pasado como parámetro */
  public scrollIntoView(anchorHash): void {
    this.timeout = setTimeout(() => {
      const anchor = document.getElementById(anchorHash);
      if (anchor) {
        anchor.focus();
        anchor.scrollIntoView();
      }
    });
  }

  public gotoTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // Convierte una fecha en string YYYYMMDD a Date
  extraerFechaParametro(parametro: string): Date {
    if ( parametro.length < 8) {
      return null;
    }
    const ano = +parametro.substr(0, 4);
    const mes = +parametro.substr(4, 2) - 1;
    const dia = +parametro.substr(6, 2);
    return new Date( ano, mes, dia);
  }

  // Convierte una fecha yyyy-mm-ddT1hh:mm:ss.xxx+00:00 a DD-MM-YY HH:MI
  public stringDateFormat(fecha: string): string {
    return fecha.substr(8, 2) + '-' + fecha.substr(5, 2) + '-' + fecha.substr(2, 2) +
      ' ' + fecha.substr(11, 5);
  }

  /**
   * Convierte una fecha en formato JSON
   * a hoy, ayer, esta semana, este mes, hace tiempo
   * @param fecha - formato 2020-09-03T21:09:24.828+00:00
   */
  tiempoTranscurrido(fecha: string): string {
    const date = new Date(fecha);
    const diff = (((new Date()).getTime() - date.getTime()) / 1000);
    const daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 240) {
      return '';
    }

    return daydiff === 0 && (
      diff < 3600 && Math.floor(diff / 60) + ' minutos' ||
      diff < 7200 && '1 hora' ||
      diff < 86400 && Math.floor(diff / 3600) + ' horas') ||

      daydiff === 1 && 'Ayer' ||
      daydiff < 7 && daydiff + ' días' ||
      daydiff === 7 && '1 semana' ||
      daydiff < 21 && Math.ceil(daydiff / 7) + ' semanas' ||
      Math.ceil(daydiff / 30) === 1 && '1 mes' ||
      daydiff < 365 && Math.ceil(daydiff / 30) + ' meses' ||
      ' hace tiempo';
  }

  /**
   * Convierte una fecha en formato JSON
   * a tiempo que falta para alcanzar desde la fecha actual
   * con mínima precisión días y máxima en minutos
   * 1 día 3 horas
   * 3 horas
   * 40 minutos
   * @param fecha - formato 2020-09-03T21:09:24.828+00:00
   * @param mini - formato reducido ( 1d 10h)
   */
  tiempoFalta(fecha: Date, mini = false): string {
    let texto = '';
    const date = new Date(fecha);
    const segundosDiff = ((date.getTime() - (new Date()).getTime()) / 1000);

    if (segundosDiff < 0 ) {
      return '';
    }

    const diasDiff = Math.floor(segundosDiff / 86400);
    if (isNaN(diasDiff) || diasDiff < 0 || diasDiff >= 240) {
      return '';
    }

    if ( diasDiff === 1 ) {
      texto = mini ? '1d ' : '1 día';
    } else if ( diasDiff > 1) {
      texto = diasDiff + (mini ? 'd ' : ' días');
    }

    if ( diasDiff < 3) {
      if ( diasDiff > 0 && !mini) {
        texto += ' y ';
      }

      const horas    = segundosDiff % (24 * 3600);
      const hoursDiff = Math.floor(horas / 3600);

      if ( hoursDiff === 1) {
        texto += (mini ? '1h ' : '1 hora');
      } else if ( hoursDiff > 1) {
        texto += hoursDiff + (mini ? 'h ' : ' horas');
      }

      if ( diasDiff === 0 && hoursDiff < 3 ) {
        if ( hoursDiff > 0 && !mini) {
          texto += ' y ';
        }

        const minutos = horas % (60 * 60);
        const minutosDiff = Math.floor(minutos / 60);
        if ( minutosDiff > 0) {
          texto += minutosDiff + (mini ? 'm' : ' minutos');
        }
      }
    }
    return texto;
  }

  // Compara dos arrays de tipos escalares ordenados
  arrayEqual(array1: any[], array2: any[]): boolean {
    return array1.length === array2.length &&
      array1.every((value, index) => value === array2[index] );
  }

  /**
   * Devuelve los días que faltan entre dos fechas
   * @param fecha - formato YYYY-MM-DD
   */
  diasFaltan( fecha: Date): number {

    const fechaFutura = new Date(fecha).getTime(); // Pasamos fecha a milisegundos
    const fechaHoy    = new Date().getTime();      // Pasamos fecha a milisegundos

    return (fechaFutura - fechaHoy ) / (1000 * 60 * 60 * 24);
  }

  minutosFaltan( fecha: Date): number {

    const fechaFutura = new Date(fecha).getTime(); // Pasamos fecha a milisegundos
    const fechaHoy    = new Date().getTime();      // Pasamos fecha a milisegundos

    return (fechaFutura - fechaHoy ) / (1000 * 60);
  }

  minutosTranscurridos( fecha: Date): number {
    const fechaDesde = new Date(fecha).getTime(); // Pasamos fecha a milisegundos
    const fechaAhora    = new Date().getTime();   // Pasamos fecha a milisegundos

    return (fechaAhora - fechaDesde) / (1000 * 60);
  }


  htmlAcutes(str: string): string {
    return String(str)
      .replace(/&ntilde;/g, 'ñ')
      .replace(/&aacute;/g, 'á')
      .replace(/&eacute;/g, 'é')
      .replace(/&iacute;/g, 'í')
      .replace(/&oacute;/g, 'o')
      .replace(/&uacute;/g, 'u');
  }

  mostrarMsgCargando(mensaje?: string): void {
    this.esperandoCarga = true;
    setTimeout( () => {
      if ( this.esperandoCarga) {
        Swal.fire({
          title: mensaje ? mensaje : 'Cargando datos', icon: 'info',
          allowEscapeKey: false, showConfirmButton: false, allowOutsideClick: false
        });
        Swal.showLoading();
      }
    }, 700);
  }

  quitarMsgCargando(): void {
    this.esperandoCarga = false;
    clearTimeout( this.timeout );
    Swal.close();
  }

  mostrarMsgError(err: any, mensaje?: string): void {
    this.esperandoCarga = false;
    if ( !mensaje || mensaje.length === 0) {
      mensaje = 'Error actualizando información.';
    }
    mensaje += '<br>' + this.privateTraduceError(err);
    Swal.fire('Error', mensaje , 'error');
  }

  getCookie(name: string): string {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return '';
  }

  privateTraduceError(error: any): string {
    console.log('tipo-error: ' + error.error);
    if (error.status === HttpStatusCode.PaymentRequired) {
      return 'Sólo para suscriptores en activo.';
    } else if ( error.status === HttpStatusCode.ImATeapot ) {
      return 'Sobrecarga. Suscriptores tienen preferencia. Inténtalo en 15 minutos.';
    } else if (error.status === HttpStatusCode.BadGateway) {
      return 'Servicio en Mantenimiento. Inténtalo en unos minutos.';
    }

    if (!error || !error.error || !error.error.message) {
      return ' indefinido';
    }

    if (error.error.message === '[14]') {
      return 'El correo electrónico ya existe o está bloqueado.';
    }
    if (error.error.message === '[16]') {
      return 'Captcha incorrecto.';
    }
    if (error.error.message === '[17]') {
      return 'El Nick ya existe o está bloqueado.';
    }
    // UNIRSE
    if (error.error.message === '[100]') {
      return 'Ya tienes la colección en tus listas.';
    }
    // COLECCION
    if (error.error.message === '[205]') {
      return 'Máximo de mensajes diarios alcanzado para tu nivel de suscripción. Mañana podrás continuar enviando nuevos mensajes.';
    }


      // MENSAJES
    else if (error.error.message === '[203]') {
      return 'No se puede continuar. El buzón de destinatario lleno.';
    }
      // ENLACES
    else if (error.error.message === '[200]') {
      return 'Sin permiso para crear nuevos enlaces. Necesitas un mínimo de 20 evaluaciones positivas.';
    } else if (error.error.message === '[251]') {
      return 'Sin permiso para crear nuevos enlaces. Muchos enlaces incorrectos.';
    } else if (error.error.message === '[252]') {
      return 'Enlace duplicado, ya existe la dirección';
    } else if (error.error.message === '[253]') {
      return 'No existe la dirección';
    } else if (error.error.message === '[254]') {
      return 'Enlace mal formado. Tiene que tener formato "https://www.ejemplo.com"';
    } else if (error.error.message === '[255]') {
      return 'El servidor al que apunta el enlace no responde. ';

      // Ofertas
    } else if (error.error.message === '[501]') {
      return 'No existe la oferta.';
    } else if (error.error.message === '[502]') {
      return 'No puedes aceptar una oferta, el estado es incorrecto.';
    } else if (error.error.message === '[503]') {
      return 'No puedes rechazar una oferta ya aceptada.';

    } else if (error.error.message === '[508]') {
      return 'El destinatario está en tu lista de ignorados.';
    } else if (error.error.message === '[509]') {
      return 'Estás incluido en la lista de ignorados del destinatario.';
    } else if (error.error.message === '[510]') {
      return 'Sólo puede haber una oferta activa por destinatario.';
    } else if (error.error.message === '[511]') {
      return 'Máximo de ofertas que puedes enviar en 24 horas, para tu nivel de suscripción. Inténtalo más tarde.';
    } else if (error.error.message === '[512]') {
      return 'Buzón lleno de ofertas del destinatario. Inténtalo más tarde.';
    } else if (error.error.message === '[513]') {
      return 'No se puede marcar como recibido un envío, que el remitente no ha marcado como envíado todavía.';
    } else if (error.error.message === '[514]') {
      return 'Obligatorio envío simultáneo. Vuelve al paso 2 y cambia Tipo de envío: Envío simultáneo.';
    } else if (error.error.message === '[515]') {
      return 'Obligatorio ofertante envía primero. Vuelve al paso 2 y cambia Tipo de envío.';
    } else if (error.error.message === '[516]') {
      return 'Obligatorio quien recibe oferta debe enviar primero. Vuelve al paso 2 y cambia Tipo de envío.';
    } else if (error.error.message === '[517]') {
      return 'Muy pronto para evaluar positivamente.';
    } else if (error.error.message === '[518]') {
      return 'Muy pronto para evaluar negativamente.';
    } else if (error.error.message === '[519]') {
      return 'Oferta inconsistente. Ya no tienes alguna falta o repetido. Pulsa [CONTRAOFERTAR] para modificar la oferta inicial.';

    } else if (error.error.message === '[520]') {
      return 'No puedes aceptar la oferta. Había sido rechazada.';
    } else if (error.error.message === '[521]') {
      return 'No puedes aceptar la oferta. Ya había caducado.';
    } else if (error.error.message === '[522]') {
      return 'No puedes aceptar la oferta. Fue revocada antes de haber sido leída.';
    } else if (error.error.message === '[523]') {
      return 'El intercambio ya está pendiente de anulación.';
    } else if (error.error.message === '[524]') {
      return 'El intercambio NO está pendiente de anulación.';
    } else if (error.error.message === '[525]') {
      return 'El aprobador de la anulación debe ser el otro usuario.';
    } else if (error.error.message === '[526]') {
      return 'Anulación no solicitada.';
    } else if (error.error.message === '[527]') {
      return 'No se puede sancionar la revocación. Plazo superado de 5 días máximo.';
    } else if (error.error.message === '[528]') {
      return 'No se puede sancionar la revocación de nuevo. Ya se sancionó con anterioridad.';
    } else if (error.error.message === '[529]') {
      return 'No se puede sancionar. La oferta fue leída con posterioridad a la fecha de revocación.';

    } else if (error.error.message === '[800]') {
      return 'Ofertante no tiene dirección de envío. Rellena los campos en preferencias.';
    } else if (error.error.message === '[801]') {
      return 'Ofertante sin Nombre. Rellena la información en Preferencias.';
    } else if (error.error.message === '[802]') {
      return 'Ofertante sin Apellidos. Rellena la información en Preferencias.';
    } else if (error.error.message === '[803]') {
      return 'Ofertante sin Dirección. Rellena la información en Preferencias.';
    } else if (error.error.message === '[804]') {
      return 'Ofertante sin Código Postal. Rellena la información en Preferencias.';
    } else if (error.error.message === '[805]') {
      return 'Ofertante sin Población. Rellena la información en Preferencias.';
    } else if (error.error.message === '[806]') {
      return 'Ofertante sin Provincia. Rellena la información en Preferencias.';

    } else if (error.error.message === '[820]') {
      return 'Ofertado no tiene dirección de envío.';
    } else if (error.error.message === '[821]') {
      return 'Ofertado no ha rellenado el Nombre en Preferencias.';
    } else if (error.error.message === '[822]') {
      return 'Ofertado no ha rellenado el Apellidos en Preferencias.';
    } else if (error.error.message === '[823]') {
      return 'Ofertado no ha rellenado el Dirección en Preferencias.';
    } else if (error.error.message === '[824]') {
      return 'Ofertado no ha rellenado el Código Postal en Preferencias.';
    } else if (error.error.message === '[825]') {
      return 'Ofertado no ha rellenado la Población en Preferencias.';
    } else if (error.error.message === '[826]') {
      return 'Ofertado no ha rellenado la Provincia en Preferencias.';

    } else if (error.error.message === '[901]') {
      return '<b>Sistema saturado. Suscriptores tienen preferencia. Inténtalo en 15 minutos</b>';
    } else if (error.error.message === '[1001]') {
      return '<b>Intercambio ya evaluado.</b>';
    } else if (error.error.message === '[1002]') {
      return '<b>Cuenta no confirmada. Revisa tu correo y pulsa en el enlace de activación</b>';
    } else if (error.error.message === '[1006]') {
      return '<b>Alcanzado máximo de Favoritos para tu nivel de suscripción. Borra alguno para añadir nuevos.</b>';
    } else if (error.error.message === '[1007]') {
      return '<b>Alcanzado máximo de Ignorados para tu nivel de suscripción. Borra alguno para añadir nuevos.</b>';
    } else {
      return error.error.message;
    }
  }
}
