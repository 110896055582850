<span [ngSwitch]="socio" (click)="onClick()">
  <ng-container *ngSwitchCase="'1'">
    <mat-icon class="icono-colaborador" title="Colaborador">bookmark_border</mat-icon>
    <span *ngIf="descripcion">&nbsp;Colaborador</span>
  </ng-container>
  <ng-container *ngSwitchCase="'2'">
    <mat-icon class="icono-vip" title="VIP">star_border</mat-icon>
    <span *ngIf="descripcion">&nbsp;VIP</span>
  </ng-container>
  <ng-container *ngSwitchCase="4">
    <mat-icon class="icono-oro" title="Rango Oro">star</mat-icon>
    <span *ngIf="descripcion">&nbsp;Oro</span>
  </ng-container>
    <ng-container *ngSwitchCase="9">
    <mat-icon class="icono-oro" title="Rango Oro">star</mat-icon>
    <span *ngIf="descripcion">&nbsp;Oro</span>
  </ng-container>
  <ng-container *ngSwitchCase="'5'">
    <mat-icon class="icono-diamante" title="Rango Diamante">star</mat-icon>
    <span *ngIf="descripcion">&nbsp;Diamante</span>
  </ng-container>
</span>
