import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../shared/login.service';
import Swal from 'sweetalert2';
import {Md5} from 'ts-md5';
import {PrefResetPasswordComponent} from '../../components/preferencias/pref-reset-password/pref-reset-password.component';
import {MatDialog} from '@angular/material/dialog';
import {UtilService} from '../../services/util.service';
import {PublicService} from '../../shared/public.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HttpStatusCode, HttpUrlEncodingCodec} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Location} from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formGroup: UntypedFormGroup;
  condicionesFormGroup: UntypedFormGroup;

  // nombre: string;
  // password: string;
  // challenge: string;
  error: string;
  muchosIntentos: boolean;
  hayNuevoCondicionado: boolean;
  captchaImage: any;
  captchaLoaded: boolean;
  headers: Map<string, string>;

  constructor(private activatedRoute: ActivatedRoute,
              private loginService: LoginService,
              private location: Location,
              private router: Router,
              public  dialog: MatDialog,
              private formBuilder: UntypedFormBuilder,
              private utilService: UtilService,
              public  publicService: PublicService) { }

  ngOnInit(): void {
    // Por si acaso había un cargando abierto
    this.utilService.quitarMsgCargando();

    this.activatedRoute.paramMap.subscribe(params => {

      this.muchosIntentos = false;
      this.hayNuevoCondicionado = false;

      // formulario condiciones
      this.condicionesFormGroup = this.formBuilder.group({
        acepto: new UntypedFormControl(false)
      });

      // formulario principal
      this.formGroup = this.formBuilder.group({
        nick: new UntypedFormControl('', [Validators.pattern('^[A-Za-z0-9_]+$')]),
        password: new UntypedFormControl(''),
        challenge: new UntypedFormControl(''),
        condicionesGroup: this.condicionesFormGroup
      });

      // Auto login si llega parámetro nick o existe la cookie "sesion_"
      // Workaround: busco la cookie "socio" porque "session_" es httponly y no puedo recuperarla
      let nick = '';
      let autologin = false;
      const sessionCookieCI = this.utilService.getCookie('session_');
      const msgCookieCI     = this.utilService.getCookie('socio');
      if ( (sessionCookieCI !== null && sessionCookieCI.length > 0) ||
           (msgCookieCI     !== null && msgCookieCI.length > 0)
         ) {
        autologin = true;
      }

      if ( params.keys.length > 0 ) {
        autologin = true;
        nick = params.get('nick');
      }
      if (autologin) {
        console.log('>>AUTO LOGIN ' + nick);
        this.formGroup.controls.nick.setValue(nick);
        this.login();
      } else {
        if ( window.location.href.indexOf('localhost') === -1 ) {
          // SOLO PERMITIMOS AUTOLOGIN. SI NO LLEGASE NICK SE DIRIGE A LA ANTIGUA VERSIÓN

          Swal.fire({
            showConfirmButton: true,
            showCancelButton: false,
            icon: 'error',
            html: 'Error 303 al conectar.' +
              '<br>Inicie de nuevo sesión con usuario y contraseña en https://cromosrepes.com'
          }).then(retorno => {
            window.location.href = environment.staticWebUrl + 'usuario/login';
          });
        }
      }
    });
  }

  public login(): void {
    const nick: string = this.formGroup.get('nick').value;
    Swal.fire({title: 'Autentificando usuario: ' + nick, icon: 'info',
      allowEscapeKey: false, showConfirmButton: false, allowOutsideClick: false});
    Swal.showLoading();

    const passwordMd5 = Md5.hashStr(this.formGroup.get('password').value).toString();
    this.loginService.login(nick, passwordMd5,
            this.formGroup.get('challenge').value,
            this.condicionesFormGroup.get('acepto')?.value
    ).subscribe( resp => {
      // const keys = resp.headers.keys();

      // El servidor habrá puesto una cookie para guardar el token JWT.
      // También devuelve el token en la cabecera de respuesta para que pueda extraer los claims
      // y guardarlos en localStorage
      const token = resp.headers.get('Authorization');

      this.loginService.saveToken( token );
      // this.cargarScriptAdsense( );

      const ultimaRuta = localStorage.getItem('ultimaRuta');
      localStorage.removeItem('ultimaRuta');
      if ( ultimaRuta !== null && ultimaRuta.indexOf('login') === -1 && ultimaRuta.indexOf('home') === -1) {
        console.log('ULTIMA RUTA ---------' + ultimaRuta);
        this.router.navigateByUrl( ultimaRuta );
      } else {
        const tirada: number = Math.floor(Math.random() * 10) + 1;
        if (tirada === 1) {
          window.location.href = environment.staticWebUrl + 'noticias/indice';
        } else {
          this.router.navigateByUrl('resumen');
        }
      }
      Swal.close();
    }, err => {
      console.log("ERROR LOGIN:" + err.status);
      let mensaje;
      let mensajeReintentar = '<br>Inicie de nuevo sesión con usuario y contraseña.';
      if (err.status == HttpStatusCode.Unauthorized ||
          err.status == HttpStatusCode.Forbidden ||
          err.status == HttpStatusCode.NotFound) {
        mensaje = 'Usuario o contraseña incorrecta.';
      } else if (err.status == HttpStatusCode.BadRequest) {
        mensaje = 'Código de verificación incorrecto.';
      } else if (err.status == HttpStatusCode.BadGateway) {
        mensaje = 'Servicio en Mantenimiento.';
        mensajeReintentar = '<br> Intente en 15 minutos.<br> Disculpa las molestias.';
      } else if (err.status == HttpStatusCode.ImATeapot) {
        mensaje = 'Sobrecarga. Suscriptores tienen preferencia.';
        mensajeReintentar = '<br> Intente en 15 minutos.';
        this.muchosIntentos = true;
      } else if (err.status == HttpStatusCode.Conflict) {
        mensaje = 'Es obligatorio aceptar las nuevas condiciones y políticas.';
        this.hayNuevoCondicionado = true;
      } else {
        mensaje = err.status;
      }
      if ( this.muchosIntentos) {
        this.getNewCaptcha();
        this.formGroup.get('challenge').setValue('');
        this.formGroup.get('password').setValue('');
      }

      // SI FALLA EL AUTOLOGIN SE DIRIGE A LA ANTIGUA VERSIÓN
      Swal.fire({
        showConfirmButton: true,
        showCancelButton: false,
        icon: 'error',
        html: 'Error conectando. ' + mensaje + mensajeReintentar
      }).then(retorno => {
        window.location.href = environment.staticWebUrl + 'usuario/login';
      });

      this.error = err.error;
      console.log('error status ' + err.status + ' codigo ' + err.error);
    });
  }

  onPedirPassword(): void {
    const dialogRef = this.dialog.open(PrefResetPasswordComponent);

    dialogRef.afterClosed().subscribe(() => { });
  }

  getNewCaptcha(): void {
    this.captchaLoaded = false;
    this.publicService.getCaptcha().subscribe( imagen => {
      this.createImageFromBlob(imagen);
      this.captchaLoaded = true;
      Swal.close();
    });
  }
  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.captchaImage = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // cargarScriptAdsense( ): void {
  //   // Si la cookie maestra: cookieconsent_status
  //   // Tiene valor allow, puedo cargar el script de Adsense
  //   const masterCookie = this.utilService.getCookie('cookieconsent_status');
  //   if ( masterCookie === null || masterCookie.length === 0) {
  //     console.log( 'cookieconsent_status no encontrado');
  //     return;
  //   }
  //   const decodedJsonString: string = decodeURIComponent(masterCookie);
  //   if ( decodedJsonString.indexOf('allow') > -1 ) {
  //     console.log( 'cookieconsent_status allow');
  //
  //     const script = this.renderer.createElement('script');
  //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8628628791781009';
  //     script.setAttribute('async', '');
  //     script.setAttribute('crossorigin', 'anonymous');
  //     this.renderer.appendChild(document.head, script);
  //   } else {
  //     console.log( 'cookieconsent_status deny');
  //   }
  // }
}
