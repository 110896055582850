import { Injectable } from '@angular/core';
import {BaseApiService} from '../services/baseApiService';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PintaNickModel} from '../models/usuarios/PintaNickModel';
import {PaisModel} from '../models/maestras/PaisModel';
import {CacheRegistrationService} from '../services/cacheService/cache-registration.service';
import {UtilService} from '../services/util.service';
import {ResetPasswordModel} from '../models/preferencias/ResetPasswordModel';

@Injectable({
  providedIn: 'root'
})
export class PublicService extends BaseApiService{

  constructor(private http: HttpClient,
              private cacheRegistrationService: CacheRegistrationService,
              private utilService: UtilService) {
    super();
    this.setEndpoint('public');
    this.cacheRegistrationService.addToCache(this.getEndpoint() + '/paises');
  }

  // Información para pintar el nick de un usuario por su identificador
  public pintaNick( usuarioId: number, refrescar= true ): Observable<PintaNickModel> {
    const url = this.getEndpoint() + '/pintaNick/' + usuarioId;

    return this.http.get<PintaNickModel>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  public pintaNickByNick( nick: string, refrescar= true ): Observable<PintaNickModel> {
    const url = this.getEndpoint() + '/pintaNickByNick/' + nick;
    this.cacheRegistrationService.addToCacheTime(url, 10);
    return this.http.get<PintaNickModel>(url, {headers: this.getHttpHeaders(refrescar)});
  }

  public getCaptcha( ): Observable<Blob> {
    const url = this.getEndpoint() + '/captcha';
    return this.http.get(url, {responseType: 'blob'});
  }

  public resetPassword(datos: ResetPasswordModel): Observable<string> {
    const url = this.getEndpoint() + '/reset-password';
    return this.http.put<string>(url, datos);
  }

  /**
   * Interesante función. Devuelve un nuevo observable del observable al que queda esperando.
   * Cuando llega la respuesta, modifica el array y devuelve el array modificado.
   * Es como un proxy de un observable.
   */
  public getPaises( refrescar= false ): Observable<PaisModel[]> {
    const url = this.getEndpoint() + '/paises';

    // Creo un nuevo observable del mismo tipo que espera
    return new Observable<PaisModel[]>((observer) => {
        this.http.get<PaisModel[]>(url, {headers: this.getHttpHeaders(refrescar)}).subscribe(
          paises => {

            // Reemplazamos tildes y eñes
            paises = paises.map( pais => {
              pais.nombre = this.utilService.htmlAcutes(pais.nombre);
              return pais;
            } );

            // Finalizamos el observable
            observer.next(paises);
            observer.complete();
          });
      }
    );

  }
}
