import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TestService} from './test.service';
import {LoginService} from './login.service';
import {ListaService} from './listas/lista.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
/**
 * MODULO COMPARTIDO SINGLETON DE LA APLICACION
 * Incluiremos en este módulo todos los servicios que quiero que seán únicos y compartidos
 * en toda la aplicación.
 */
export class SharedModule {

  /**
   * Guard. Que comprueba que ningún otro módulo reimporta este módulo, para que haya una única
   * instancia.
   * @param parentModule - módulo padre
   */
  constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
    if (parentModule) {
      throw new Error(
        'SharedModule is already loaded. Import it in the AppModule only. Has importado en un módulo que no es el AppModule el módulo compartido');
    }
  }

  /**
   * método estático para que el SharedModule sea un singleton a todos los módulos
   */
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [ TestService, LoginService, ListaService ]
    };
  }
}
