<ng-container *ngIf="loaded && listaColecciones.length > 0" >
  <table mat-table [dataSource]=listaColecciones class="mat-elevation-z8 mat-table table table-striped mx-3"
         matSort (matSortChange)="sortLista($event)">

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
      <td mat-cell *matCellDef="let row" matRipple [routerLink]="'/coleccion/'+row.escNombre">
        <span *ngIf="!row.popular">{{row.nombre}}</span>
        <span *ngIf="row.popular"><b>{{row.nombre}}</b></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="editorialNombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Editorial</th>
      <td mat-cell *matCellDef="let row" matRipple [routerLink]="'/coleccion/'+row.escNombre">
        <span>{{row.editorialNombre}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="anoPublica">
      <th mat-header-cell *matHeaderCellDef arrowPosition='before' mat-sort-header> Año</th>
      <td mat-cell *matCellDef="let row" matRipple [routerLink]="'/coleccion/'+row.escNombre">
        <span>{{row.anoPublica}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="coleccionistas">
      <th mat-header-cell *matHeaderCellDef arrowPosition='before' mat-sort-header> Listas</th>
      <td mat-cell *matCellDef="let row" matRipple [routerLink]="'/coleccion/'+row.escNombre">
        <span>{{row.coleccionistas}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnasActivas"></tr>
    <tr mat-row *matRowDef="let row; columns: columnasActivas;" class="row-hover"></tr>
  </table>
</ng-container>
