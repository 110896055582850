import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailRepeatComponent } from './email-repeat/email-repeat.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import { PasswordRepeatComponent } from './password-repeat/password-repeat.component';
import { CheckCondicionesComponent } from './check-condiciones/check-condiciones.component';
import {CompartidoModule} from '../compartido/compartido.module';

@NgModule({
  declarations: [
    EmailRepeatComponent,
    PasswordRepeatComponent,
    CheckCondicionesComponent
  ],
  exports: [
    EmailRepeatComponent,
    PasswordRepeatComponent,
    CheckCondicionesComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CompartidoModule
  ]
})
export class FormulariosModule { }
