<form [formGroup]="emailForm">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Email:</mat-label>
    <input type="email" matInput
              placeholder="Escribe tu dirección de correo electrónico" maxlength="100"
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" required
              formControlName="email" #myElement (focus)="myElement.setSelectionRange(0,100)">
    <!-- Situar el cursor en el primer carácter del campo setSelectionRange(0,0) -->
    <div *ngIf="email.touched && email.errors?.required" class="mat-error">Campo obligatorio</div>
    <div *ngIf="email.touched && email.errors?.pattern" class="mat-error">Formato incorrecto</div>
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width" *ngIf="emailForm.controls['email'].value != emailOriginal">
    <mat-label>Repite el nuevo email:</mat-label>
    <input type="email" matInput
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
              placeholder="Repite tu nueva dirección de correo electrónico" maxlength="100"
              formControlName="emailRepeat" #myElement (focus)="myElement.setSelectionRange(0,100)">
    <!-- Situar el cursor en el primer carácter del campo setSelectionRange(0,0) -->
    <div *ngIf="emailForm.errors?.noMatch && !email.errors" class="mat-error">
      El email repetido no coincide.
    </div>
  </mat-form-field>
</form>
