import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-icono-socio',
  templateUrl: './icono-socio.component.html',
  styleUrls: ['./icono-socio.component.css']
})
// https://medium.com/@yonem9/angular-c%C3%B3mo-narices-se-anidan-componentes-3f4a7124d751
export class IconoSocioComponent implements OnInit {
  @Input() socio: number;
  @Input() descripcion = false;
  @Input() bloqueo = false;
  ruta: any;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  onClick(): void {
    if (!this.bloqueo) {
      this.router.navigateByUrl('suscripcion');
    }
  }
}
