import { Injectable } from '@angular/core';
import {CacheRegisterModel} from './cacheRegisterModel';

/**
 * Servicio de registro de las url cacheables
 *  https://medium.com/@vikeshm/data-caching-angular-http-interceptor-2d87f95e2340
 *  09/07/2023 - Amplio el servicio con tiempo de caducidad personalizado.
 */
@Injectable({
  providedIn: 'root'
})
export class CacheRegistrationService {
  // Map donde guardar como clave la url y como valor los minutos de caducidad o 0 si no caduca.
  private services = new Map<string, number>();

  public isCacheable(serviceUri: string): any {
    const valorEncontrado = this.services.get(serviceUri);
    return (valorEncontrado || valorEncontrado === 0 ) ? valorEncontrado : null;
  }

  public addToCache(serviceUri: string): void {
    this.addToCacheTime(serviceUri, 0);
  }

  public addToCacheTime(serviceUri: string, minutos: number): void {
    // Check if not already added to map
    if ( this.isCacheable(serviceUri) == null ) {
      this.services.set(serviceUri, minutos);
    }
  }
}
