<ng-container *ngIf="loaded">
    <a *ngIf="!bloqueo" routerLink="/listas/{{nick}}" title="Truco: pulsa botón central del ratón, para abrir en nueva pestaña">
      <span [class]="cssClassNick">{{nick}}</span></a>
    <ng-container *ngIf="bloqueo"><span [class]="cssClassNick" >{{nick}}</span></ng-container>

    <ng-container *ngIf="doblelinea"><br/></ng-container>

    <a *ngIf="!bloqueo" routerLink="/evalua/recibidas/{{nick}}" [class]="cssClassEvalua" title="Truco: pulsa botón central del ratón, para abrir en nueva pestaña">
      ({{pos}}<span *ngIf="neg<-1"  class="danger"><strong>{{neg}}</strong></span>
              <span *ngIf="neg==-1" class="warning">{{neg}}</span>)
    </a>
    <ng-container *ngIf="bloqueo" [class]="cssClassEvalua" title="Evaluaciones">
      ({{pos}}<span *ngIf="neg<-1"  class="danger"><strong>{{neg}}</strong></span>
              <span *ngIf="neg==-1" class="warning">{{neg}}</span>)
    </ng-container>
    <app-icono-socio [socio]="soc" [bloqueo]="bloqueo"></app-icono-socio>
</ng-container>
