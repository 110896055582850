<small>
  <p>Información básica sobre protección de datos<br>
  Identidad: Cuatro Datos Soluciones y Desarrollo S.L. - CIF: B85910206
    Dir. postal: C/ Andrés Mellado, 19 28015 Madrid .- Correo elect: cromosrepes@gmail.com<br>
  “En nombre de la empresa tratamos la información que nos facilita con el fin de prestarles el servicio solicitado y
    realizar la facturación del mismo. Los datos proporcionados se conservarán mientras se mantenga la relación o durante
    los años necesarios para cumplir con las obligaciones legales. Los datos no se cederán a terceros salvo en los casos
    en que exista una obligación legal. Usted tiene derecho a obtener confirmación sobre si en Cuatro Datos Soluciones y
    Desarrollo S.L. estamos tratando sus datos personales por tanto tiene derecho a acceder a sus datos personales, rectificar
    los datos inexactos o solicitar su supresión cuando ya no sean necesarios".
    <a href="https://cromosrepes.com/ayuda/privacidad#rgpd"> +info</a></p>
</small>
