import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NickComponent} from './nick/nick.component';
import {IconoSocioComponent} from './icono-socio/icono-socio.component';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { DificultadComponent } from './dificultad/dificultad.component';
import { BanderaComponent } from './bandera/bandera.component';
import { RgpdResumenComponent } from './rgpd-resumen/rgpd-resumen.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MiniNickComponent } from './mini-nick/mini-nick.component';
import { IconoNotificaComponent } from '../../initial/title/icono-notifica/icono-notifica.component';
import { ListaColeccionesComponent } from './lista-colecciones/lista-colecciones.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatRippleModule} from '@angular/material/core';
import { IconoVacacionesComponent } from './icono-vacaciones/icono-vacaciones.component';
import { LeftTrimPipe } from './left-trim-pipe/left-trim.pipe';
import { BtnCloseDialogComponent } from './btn-close-dialog/btn-close-dialog.component';
import {OfertaBalanceComponent} from './oferta-balance/oferta-balance.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { GoTopComponent } from './go-top/go-top.component';

/**
 * ================================================================================================
 *  Modulo para compartir componentes, pipes y módulos de terceros (CommonModulo, FormsModule...)
 *  !! NO es singleton. Por eso aquí no puede ir ningún servicio
 *  !! NO se debe importar este módulo en AppModule
 * ================================================================================================
 */

@NgModule({
  declarations: [NickComponent, IconoSocioComponent, DificultadComponent, BanderaComponent,
    RgpdResumenComponent, MiniNickComponent,  IconoNotificaComponent, ListaColeccionesComponent,
    IconoVacacionesComponent, LeftTrimPipe, BtnCloseDialogComponent, OfertaBalanceComponent, GoTopComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    MatTooltipModule
  ],
    exports: [
        RouterModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,

        NickComponent,
        OfertaBalanceComponent,
        IconoSocioComponent,
        DificultadComponent,
        BanderaComponent,
        RgpdResumenComponent,
        MiniNickComponent,
        IconoNotificaComponent,
        ListaColeccionesComponent,
        IconoVacacionesComponent,
        LeftTrimPipe,
        BtnCloseDialogComponent,
        GoTopComponent
    ]
})
export class CompartidoModule { }
