<div class="balance" *ngIf="loginService.getSocio() >1" (click)="onClick()">
  <div class="balance-izda">
    <progress max="100" value="{{ratioPides*100/(ratioOfreces+0.0001)}}"><sup><i>{{ratioPides}}</i></sup></progress>
  </div>
  <div class="balance-central" matTooltip="Relación entre lo que recibes y lo que ofreces">
    &nbsp;<div>{{ratioPides}}</div> <mat-icon>balance</mat-icon> <div>{{ratioOfreces}}</div>&nbsp;
  </div>
  <div class="balance-dcha">
    <progress max="100" value="{{ratioPides === 0 ? 0 : ratioOfreces*100/(ratioPides+0.0001)}}"></progress>
  </div>
</div>

