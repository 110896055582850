<!-- Karma {{usuario.k}}-->
<ng-container *ngIf="loaded">
<!--  <div style="float: left">-->
    <app-mini-nick [nick]="usuario.nick" [soc]="usuario.soc"
                   [pos]="usuario.pos" [neg]="usuario.neg" [ser]="usuario.ser"
                   [bloqueo]="bloqueo">
    </app-mini-nick>

    <span name="usu-velocidad" size="sm" *ngIf="usuario.v===1">
      <app-icono-vacaciones></app-icono-vacaciones>
    </span>
    <!-- font-size: 0. Sirve para que los span se junten y no queden separados. Por eso luego aplico font-size: 10px -->
    <small name="usu-ult" *ngIf="usuario.v!==1" style="font-size: 0;">
      <span class="perfect" *ngIf="usuario.ult<=24" title="Conectado hoy" style="font-size: 10px;"> hoy</span>
      <span class="perfect" *ngIf="usuario.ult>24 && usuario.ult<=48" title="Conectado ayer" style="font-size: 10px;"> ayer</span>
      <span class="normal"  *ngIf="usuario.ult>48 && usuario.ult<=168" title="Conectado ésta semana" style="font-size: 10px;"> ésta semana</span>
      <span class="normal"  *ngIf="usuario.ult>168  && usuario.ult<=720" title="Conectado hace menos de un mes" style="font-size: 10px;"> éste mes</span>
      <span class="poco"    *ngIf="usuario.ult>720" title="Conectado hace más de un mes" style="font-size: 10px;"> hace tiempo</span>
      <!-- TODO RUTA A VELOCIDAD -->
      <!-- https://unicode-table.com/es/blocks/counting-rod-numerals/ -->
      <ng-container name="usu-velocidad"  *ngIf="usuario.v!==1 && usuario.ult<720" style="font-size: 0;">
        <span *ngIf="usuario.vel>=90"                     [ngStyle]="{'color': '#009900', 'opacity': '0.5'}"
              title="Siempre contesta" ><mat-icon style="font-size: 10px;padding-top: 10px;">battery_full</mat-icon></span>
        <span *ngIf="usuario.vel>=70 && usuario.vel<90"   [ngStyle]="{'color': '#009900', 'opacity': '0.6'}"
              title="Suele contestar" ><mat-icon style="font-size: 10px;padding-top: 10px;">battery_5_bar</mat-icon></span>
        <span *ngIf="usuario.vel>=50 && usuario.vel<70"   [ngStyle]="{'color': 'olive', 'opacity': '0.6'}"
              title="A veces contesta"><mat-icon style="font-size: 10px;padding-top: 10px;">battery_3_bar</mat-icon></span>
        <span *ngIf="usuario.vel>=30 && usuario.vel<50"   [ngStyle]="{'color': 'olive', 'opacity': '0.6'}"
              title="Pocas veces contesta"><mat-icon style="font-size: 10px;padding-top: 10px;">battery_2_bar</mat-icon></span>
        <span *ngIf="usuario.vel<30"                     [ngStyle]="{'color': 'lightgray'}"
              title="No suele contestar"><mat-icon style="font-size: 10px;padding-top: 10px;">battery_0_bar</mat-icon></span>
      </ng-container>
    </small>



    <!-- EJEMPLO DE USO DE ICONOS SVG 2020-12-30. Se deshechó por rendimiento.
    https://www.digitalocean.com/community/tutorials/angular-custom-svg-icons-angular-material
    Para usar los iconos en NickComponent.ts hay que declarar CustomIconService en el constructor y
    llamar al método init del servicio (this.customIconService.init)
    -->
    <!--<span name="usu-velocidad"  *ngIf="usuario.v!==1 && usuario.ult<720">
      <mat-icon *ngIf="usuario.vel>=90"                     [ngStyle]="{'color': '#009900'}"
               title="Siempre contesta" size="sm" svgIcon="battery4"></mat-icon>
      <mat-icon *ngIf="usuario.vel>=70 && usuario.vel<90"   [ngStyle]="{'color': '#009900'}"
               title="Suele contestar" size="sm" svgIcon="battery3"></mat-icon>
      <mat-icon *ngIf="usuario.vel>=50 && usuario.vel<70"   [ngStyle]="{'color': 'olive'}"
               title="A veces contesta" size="sm" svgIcon="battery2"></mat-icon>
      <mat-icon *ngIf="usuario.vel>=30 && usuario.vel<50"   [ngStyle]="{'color': 'olive'}"
               title="Pocas veces contesta" size="sm" svgIcon="battery1"></mat-icon>
      <mat-icon *ngIf="usuario.vel<30"                     [ngStyle]="{'color': 'lightgray'}"
               title="No suele contestar" size="sm" svgIcon="battery0"></mat-icon>
    </span-->

  <!-- TODO BANDERA DE PAIS -->
  <app-bandera [paisId]="usuario.pais" *ngIf="usuario.pais!=='ES'"></app-bandera>
<!--  </div>-->
</ng-container>
