<div>
  <div class="contenedor">

    <header class="header">
      <!-- Título y menu -->
      <app-title></app-title>
    </header>


    <div class="banner" id="banner1" name="banner1">
      <!-- banner vertical izquierda -->
      <!-- 2024-03-05 Adsense desactivado
      <ins class="adsbygoogle"
           style="display:block"
           data-ad-client="ca-pub-8628628791781009"
           data-ad-slot="5151858901"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins> -->
      <!-- Cargamos la publicidad en index.html
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>-->
    </div>

    <!-- Contenido principal. Centrado -->
    <div id="contenido" class="contenido" >
      <!-- SPA dentro de esta etiqueta se irá cargando el contenido dinámicamente según la ruta -->
      <router-outlet></router-outlet>
    </div>

    <div class="banner2" id="banner2" name="banner2">
      <!-- banner vertical derecha -->
      <!-- 2024-03-05 Adsense desactivado
      <ins class="adsbygoogle"
           style="display:block"
           data-ad-client="ca-pub-8628628791781009"
           data-ad-slot="5151858901"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins> -->
      <!-- Cargamos la publicidad en index.html
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>-->
    </div>

    <footer class="footer">
      <!-- Pie -->
      <app-footer></app-footer>
    </footer>
  </div>

  <app-go-top anchor="top"></app-go-top>
</div>
