import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './initial/home/home.component';
import {LoginComponent} from './initial/login/login.component';
import {environment} from '../environments/environment';

/*
import {CanActivateLogged} from './services/can.activate.logged';
import {BuscadorComponent} from './components/buscador/buscador.component';*/


const routes: Routes = [
  { path: '',  redirectTo: '/home', pathMatch: 'full'}, // si no se especifica ruta redirigimos a /home
  { path: 'admin'       , loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'ayuda'       , loadChildren: () => import('./components/ayuda/ayuda.module').then(m => m.AyudaModule) },
  { path: 'coleccion'   , loadChildren: () => import('./components/coleccion/coleccion.module').then(m => m.ColeccionModule) },
  { path: 'colecciones' , loadChildren: () => import('./components/colecciones/colecciones.module').then(m => m.ColeccionesModule) },
  { path: 'evalua'      , loadChildren: () => import('./components/eval/evalua.module').then(m => m.EvaluaModule) },
  { path: 'home'        ,  component: HomeComponent },
  { path: 'listas'      , loadChildren: () => import('./components/listas/listas.module').then(m => m.ListasModule) },
  { path: 'login'       ,  component: LoginComponent },
  { path: 'login/:nick' ,  component: LoginComponent },
  { path: 'mensajes'    , loadChildren: () => import('./components/mensajes/mensajes.module').then(m => m.MensajesModule) },
  { path: 'preferencias', loadChildren: () => import('./components/preferencias/preferencias.module').then(m => m.PreferenciasModule) },
  { path: 'politica'    , loadChildren: () => import('./components/politica/politica.module').then(m => m.PoliticaModule) },
  { path: 'registro'    , loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule) },
  { path: 'resumen'     , loadChildren: () => import('./components/resumen/resumen.module').then(m => m.ResumenModule) },
  { path: 'signup'      , loadChildren: () => import('./components/signup/signup.module').then(m => m.SignupModule) },
  { path: 'usuarios'    , loadChildren: () => import('./components/usuarios/usuarios.module').then(m => m.UsuariosModule) },
  { path: 'wiki'        , loadChildren: () => import('./components/wiki/wiki.module').then(m => m.WikiModule) },
  { path: 'paypal'      , loadChildren: () => import('./components/paypal/paypal.module').then(m => m.PaypalModule) },
  { path: 'stripe'      , loadChildren: () => import('./components/stripe/stripe.module').then(m => m.StripeModule) },
  { path: 'suscripcion' , loadChildren: () => import('./components/suscripcion/suscripcion.module').then(m => m.SuscripcionModule) },
  { path: 'cambios', loadChildren: () => import('./components/cambios/cambios.module').then(m => m.CambiosModule) },
  { path: 'ranking', loadChildren: () => import('./components/ranking/ranking.module').then(m => m.RankingModule) },


  // TODO establecer un componente para página no encontrada
  //  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
